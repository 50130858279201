import React, { useEffect, useState } from "react";
import "./App.css";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import layouts from "convert-layout";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from 'jotai';

export const fromAtom = atomWithStorage('from', 'en');
export const toAtom = atomWithStorage('to', 'he');



layouts["en"] = {
  toEn: (v: string) => v,
  fromEn: (v: string) => v,
};

function App() {
  const [layoutNames] = useState<string[]>([...Object.keys(layouts)].sort());
  const [from, setFrom] = useAtom(fromAtom);
  const [to, setTo] = useAtom(toAtom);
  const [fromVal, setFromVal] = useState<string>("");
  const [toVal, setToVal] = useState<string>("");

  useEffect(() => {
    if (to && from) {
      setToVal(layouts[to].fromEn(layouts[from].toEn(fromVal)));
    }
  }, [from, to, fromVal]);

  const renderLayoutSelect = (
    label = "",
    val: string | undefined,
    setVal: (v: string) => void,
    textLabel = "",
    textVal: string | undefined,
    setTextVal: (v: string) => void
  ) => {
    return (
      <div>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: "100%", marginLeft: 0 }}
        >
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            labelId={label}
            value={val}
            onChange={(e) => setVal(e.target.value)}
            label={label}
          >
            {layoutNames.map((layoutName) => (
              <MenuItem value={layoutName} key={layoutName}>
                {layoutName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          aria-label={textLabel}
          multiline
          value={textVal}
          onChange={(e) => {
            setTextVal(e.currentTarget.value);
          }}
          placeholder={textLabel}
          style={{ width: "100%" }}
          minRows={6}
        />
      </div>
    );
  };

  return (
    
      <Grid container spacing={0} style={{ marginTop: "30px" }}>
        <Grid xs={0.5}></Grid>
        <Grid xs={5}>
          {renderLayoutSelect(
            "Convert from",
            from,
            setFrom,
            "Paste something to convert...",
            fromVal,
            setFromVal
          )}
          <Button sx={{fontSize: '1.0em', color: '#999', width: "100%"}} onClick={(e) => {
            navigator.clipboard.readText().then(setFromVal);
            const foo = e.currentTarget;
            foo.innerText = 'Pasted from your clipboard'

            setTimeout(() => {foo.innerText = 'Click to Paste'}, 1500)
          }}>Click to Paste</Button>
        </Grid>
        <Grid xs={0.5} sx={{textAlign: 'center', paddingTop: '6px', minWidth: '50px'}}>
          <Button sx={{fontSize: '1.9em', color: '#999'}} onClick={() => {
            const oldFrom = from;
            const oldTo = to;
            setTo(oldFrom);
            setFrom(oldTo);
          }}>&laquo;&raquo;</Button>
        </Grid>
        <Grid xs={5}>
          {renderLayoutSelect(
            "Convert to",
            to,
            setTo,
            "It will be converted here...",
            toVal,
            setToVal
          )}
          <Button sx={{fontSize: '1.0em', color: '#999', width: "100%"}} onClick={(e) => {
            navigator.clipboard.writeText(toVal);
            const foo = e.currentTarget;
            foo.innerText = 'Copied to your clipboard'

            setTimeout(() => {foo.innerText = 'Click to Copy'}, 1500)
          }}>Click to Copy</Button>
        </Grid>
        <Grid xs={0.5}></Grid>
      </Grid>
  );
}

export default App;
